import React from "react"
import { graphql } from "gatsby"
import Thanks from "@components/Solutions/Thanks/Thanks"

const Success = ({ data: { homeHero1 } }) => {
  return <Thanks homeHero1={homeHero1} />
}

export default Success

export const query = graphql`
  query {
    homeHero1: file(relativePath: { eq: "img-min/Image-Hero-1.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED formats:[AUTO, WEBP, AVIF] quality: 90)
      }
    }
  }
`
