import React from "react"
import { Link } from "gatsby"

const ClimateCheckLogo: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <Link to="/" title="home">
      <div className={`logo ${color}`}>
        {color === "white" && (
          <img src="/logo-new-r.svg" alt="" />
        )}
        {color === "black" && (
          <img src="/logo-new-r-black.svg" alt="" />
        )}
        {color === "green" && (
          <img src="/logo-new-r-green.svg" alt="" />
        )}
      </div>
    </Link>
  )
}



export default ClimateCheckLogo
